<template>
  <div class="body">
    <b-row class="ml-2 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        {{ `Created at:` }}
        <strong v-if="groupInfo" class="text-primary pl-25">
          {{ groupInfo.created_at }}</strong
        >
        <b-skeleton
          class="d-flex inline-block mb-0 ml-1"
          width="200px"
          v-else
          type="text"
        ></b-skeleton>
      </div>
      <div class="mr-2 d-flex inline-block align-items-center">
        {{ `Updated at:` }}
        <strong v-if="groupInfo" class="text-primary pl-25">
          {{ groupInfo.updated_at }}</strong
        >
        <b-skeleton
          class="d-flex inline-block mb-0 ml-1"
          width="200px"
          v-else
          type="text"
        ></b-skeleton>
      </div>
    </b-row>

    <hr class="my-2" />

    <b-row class="ml-2 d-flex justify-content-between">
      <div>
        <b-button
          v-if="groupInfo"
          variant="outline-info"
          v-clipboard:copy="authHeader"
          v-clipboard:success="onCopy"
        >
          <feather-icon class="cursor-pointer" icon="FileTextIcon" />
          {{ $t("transmission.buttons.copy_auth_header") }}
        </b-button>
        <b-skeleton class="mb-0" v-else type="button"></b-skeleton>
      </div>
      <div class="mr-2" v-if="!userCantEdit">
        <b-button
          v-if="groupInfo"
          variant="outline-danger"
          @click="deleteTransmission"
        >
          <feather-icon class="cursor-pointer" icon="TrashIcon" />
          {{ $t("transmission.buttons.delete") }}
        </b-button>
        <b-skeleton class="mb-0" v-else type="button"></b-skeleton>
      </div>
    </b-row>

    <hr class="my-2" />

    <b-col cols="12">
      <!-- GROUP INFO -->
      <b-row>
        <b-col xl="3" lg="3" md="6" sm="12" cols="12">
          <b-form-group label="Identifier" label-for="identifier">
            <b-input-group v-if="groupInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <div
                  class="btn btn-info text-white py-25 px-75"
                  v-clipboard:copy="groupInfo.identifier"
                  v-clipboard:success="onCopy"
                  :title="$t('tooltip.clipboard')"
                >
                  <feather-icon class="cursor-pointer" icon="FileTextIcon" />
                </div>
              </b-input-group-prepend>
              <b-form-input
                id="identifier"
                placeholder="Identifier"
                readonly
                :value="groupInfo.identifier"
              />
              <!-- <b-input-group-append is-text>
                <b-button
                  variant="ml-1 btn btn-warning text-white py-25 px-75"
                  @click="editTransmission('Identifier')"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="3" lg="3" md="6" sm="12" cols="12">
          <b-form-group label="Group Name" label-for="group-name">
            <b-input-group v-if="groupInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ApertureIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="group-name"
                placeholder="Group Name"
                :value="`${groupInfo.name}`"
                readonly
              />
              <b-input-group-append is-text v-if="!userCantEdit">
                <b-button
                  variant="ml-1 btn btn-warning text-white py-25 px-75"
                  @click="editTransmission('Name', groupInfo.name)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="2" md="6" sm="12" cols="12">
          <b-form-group label="Group Status" label-for="group-status">
            <b-input-group v-if="groupInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="groupInfo.active == true"
                  icon="CheckCircleIcon"
                  style="color: green"
                />
                <feather-icon v-else icon="XCircleIcon" style="color: red" />
              </b-input-group-prepend>
              <b-form-input
                id="group-status"
                placeholder="Group Status"
                :value="`${groupInfo.active == true ? 'Active' : 'Inactive'}`"
                readonly
              />
              <b-input-group-append is-text>
                <b-button
                  v-if="!userCantEdit"
                  variant="ml-1 btn btn-warning text-white py-25 px-75"
                  @click="editTransmission('Active')"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="4" lg="4" md="6" sm="12" cols="4">
          <b-form-group label="Description" label-for="description">
            <b-input-group v-if="groupInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="description"
                placeholder="Description"
                readonly
                :value="`  ${
                  groupInfo.description ? groupInfo.description : 'Not Informed'
                }`"
              />
              <b-input-group-append is-text>
                <b-button
                  variant="ml-1 btn btn-warning text-white py-25 px-75"
                  v-if="!userCantEdit"
                  @click="editTransmission('Description')"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="my-1" />
      <!-- MYSQL SERVER INFO -->
      <b-row>
        <b-col xl="3" lg="3" md="6" sm="12" cols="12">
          <b-form-group
            label="Mysql Server Identifier"
            label-for="mysql-server-identifier"
          >
            <b-input-group v-if="mysqlDb" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="DatabaseIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="mysql-server-identifier"
                placeholder="Mysql Server Identifier"
                readonly
                :value="mysqlDb.identifier"
              />
              <!-- <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editTransmission('Mysql_Db_Server')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="2" md="6" sm="12" cols="12">
          <b-form-group
            label="Mysql Server Status"
            label-for="mysql-server-status"
          >
            <b-input-group v-if="mysqlDb" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="mysqlDb.enum_status.label == 'Active'"
                  icon="CheckCircleIcon"
                  style="color: green"
                />
                <feather-icon v-else icon="XCircleIcon" style="color: red" />
              </b-input-group-prepend>
              <b-form-input
                id="mysql-server-status"
                placeholder="Mysql Server Status"
                readonly
                :value="mysqlDb.enum_status.label"
              />
              <!-- <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editTransmission('Mysql_Db_Server')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="my-1" />
      <!-- PROCESSED LOG -->
      <b-row>
        <b-col xl="3" lg="3" md="6" sm="12" cols="12">
          <b-form-group
            label="Processed Log Server Identifier"
            label-for="processedlog-server-identifier"
          >
            <b-input-group v-if="processedLog" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="DatabaseIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="processedlog-server-identifier"
                placeholder="Processed Log Server Identifier"
                readonly
                :value="processedLog.identifier"
              />
              <!-- <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editTransmission('Processed_Log_Server')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="2" md="6" sm="12" cols="12">
          <b-form-group
            label="Processed Log Server Status"
            label-for="processedlog-server-status"
          >
            <b-input-group v-if="processedLog" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="processedLog.enum_status.label == 'Active'"
                  icon="CheckCircleIcon"
                  style="color: green"
                />
                <feather-icon v-else icon="XCircleIcon" style="color: red" />
              </b-input-group-prepend>
              <b-form-input
                id="processedlog-server-status"
                placeholder="Processed Log Server Status"
                readonly
                :value="processedLog.enum_status.label"
              />
              <!-- <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editTransmission('ProcessedLogStatus')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="my-1" />
      <!-- SYSTEM LOG -->
      <b-row>
        <b-col xl="3" lg="3" md="6" sm="12" cols="12">
          <b-form-group
            label="System Log Server Identifier"
            label-for="systemLog-server-identifier"
          >
            <b-input-group v-if="systemLog" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="DatabaseIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="systemLog-server-identifier"
                placeholder="System Log Server Identifier"
                readonly
                :value="systemLog.identifier"
              />
              <!-- <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editTransmission('SystemLogIdentifier')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <div v-else>
              <b-skeleton type="input"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="2" md="6" sm="12" cols="12">
          <b-form-group
            label="System Log Server Status"
            label-for="systemLog-server-status"
          >
            <b-input-group v-if="systemLog" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="systemLog.enum_status.label == 'Active'"
                  icon="CheckCircleIcon"
                  style="color: green"
                />
                <feather-icon v-else icon="XCircleIcon" style="color: red" />
              </b-input-group-prepend>
              <b-form-input
                id="systemLog-server-status"
                placeholder="System Log Server Status"
                readonly
                :value="systemLog.enum_status.label"
              />
              <!-- <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editTransmission('SystemLogStatus')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
            <b-col v-else>
              <b-skeleton type="input"></b-skeleton>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <base-modal
      v-if="groupInfo"
      :initialDataModal="{
        newGroupName: groupInfo.name,
        newGroupActive: groupInfo.active,
        newGroupDescription: groupInfo.description,
      }"
    />
    <hr class="my-2" />
    <!-- <pre>
    {{ JSON.stringify(groupInfo) }}
  </pre> -->
  </div>
</template>

<script>
import BaseModal from "@/layouts/components/BaseModal.vue";
import { makeToast } from "@/layouts/components/Popups";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
    BSkeleton,
  },
  data() {
    return {
      groupInfo: null,
      mysqlDb: null,
      processedLog: null,
      systemLog: null,
    };
  },
  computed: {
    authHeader() {
      return JSON.stringify({ "x-transmission": `Group ${this.groupInfo.identifier}` });
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.fetchTransmissionGroupInfo();
    },
    fetchTransmissionGroupInfo() {
      this.$store
        .dispatch("getTransmissionGroupById", this.$route.params)
        .then((response) => {
          this.$store.commit("SET_CURRENT_GROUP", response);
          this.groupInfo = response;
          this.mysqlDb = response.mysql_db;
          this.processedLog = response.processed_log;
          this.systemLog = response.system_log;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTransmission(action) {
      this.$store.dispatch("setGroupAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editTransmission${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    deleteTransmission() {
      this.$bvModal.msgBoxConfirm(this.$t("transmission.modal.delete.message", { name: this.groupInfo.name}), {
        title: this.$t("transmission.modal.delete.title"),
        size: 'md',
        okVariant: 'warning',
        okTitle: this.$t("common.modal.yes"),
        cancelTitle: this.$t("common.modal.no"),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if(value) {
          this.$store
            .dispatch("deleteTransmissionById", this.groupInfo.id)
            .then(() => {
              this.$router.push({ name: 'home' })
              makeToast({
                title: this.$t("transmission.toast.delete.success.title"),
                text: this.$t("transmission.toast.delete.success.message"),
                variant: "success",
                icon: "CheckIcon",
              }); 
            })
            .catch((error) => {
              console.log(error)
              makeToast({
                title: this.$t("transmission.toast.delete.error.title"),
                text: this.$t("transmission.toast.delete.error.message"),
                variant: "danger",
                icon: "XIcon",
              }); 
            });
        }
      })
    },
    onCopy() {
      makeToast({
        title: this.$t("common.toast.copy_to_clipboard.success.title"),
        text: this.$t("common.toast.copy_to_clipboard.success.message"),
        variant: "success",
        icon: "CheckIcon",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>